import React, { useContext, useEffect } from "react";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

import Layout from "../components/layout";
import SEO from "../components/seo";

function PrivacyPolicy() {
  const dispatch = useContext(GlobalDispatchContext);

  useEffect(() => {
    dispatch({ type: "SET_NAMES", payload: [] });
    dispatch({ type: "SET_SUBMITTED", payload: false });
    dispatch({ type: "SET_SUCCESS", payload: false });
  }, []); //eslint-disable-line

  return (
    <Layout>
      <SEO keywords={[`privacy`, `policy`]} title="Privacy Policy" />

      <section className="flex flex-col items-center md:flex-row font-serif px-6 mb-4">
        <div className="container mx-auto mt-8">
          <h1 className="text-2xl font-bold">Privacy Policy</h1>
          <br />
          <p>
            VEYM Don Bosco Chapter and the Holy Vietnamese Martyrs Catholic
            Church are committed to protecting the privacy and accuracy of
            confidential information to the extent possible, subject to
            provisions of state and federal law. Other than as required by laws
            that guarantee public access to certain types of information, or in
            response to subpoenas or other legal instruments that authorize
            access, personal information is not actively shared. In particular,
            we do not re-distribute or sell personal information collected on
            our web servers.
          </p>
          <br />
          <h5 className="font-bold text-lg">Information collected</h5>
          <p>
            This site requires users to give contact information in the form of
            first and last names. Contact information from the form is used only
            to track who has submitted the form and will not be sold to another
            party.{" "}
          </p>
          <br />
          <h5 className="font-bold text-lg">Use of collected information</h5>
          <p>
            This site will only use personal information collected from this
            site for the purpose of tracking who has submitted the form each
            week.
          </p>
          <br />
          <h5 className="font-bold text-lg">
            Distribution of collected information
          </h5>
          <p>
            This site will not disclose, without your consent, personal
            information collected about you, except for certain explicit
            circumstances in which disclosure is required by law.
          </p>
          <br />
          <h5 className="font-bold text-lg">Privacy Statement Revisions</h5>
          <p>
            This Privacy Statement was last revised on October 1, 2020. We may
            change this Privacy Statement at any time and for any reason. We
            encourage you to review this Privacy Statement each time you visit
            the web site. If we make a significant change to our Privacy
            Statement, we will post a notice on the homepage of our web site for
            a period of time after the change is made.
          </p>
          <br />
          <h5 className="font-bold text-lg">How to contact us</h5>
          <p>Please contact HVMCC if you have any questions or concerns.</p>
        </div>
      </section>
    </Layout>
  );
}

export default PrivacyPolicy;
